<template>
    <v-container class="w-container-domande" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                                                                                    background-color: white;
                                                                                    color: black;
                                                                                    font-weight: bold;
                                                                                    padding: 4px;
                                                                                    font-size: 10px;
                                                                                    min-width:100vw;
                                                                                    border-color: #0084f3">{{ titolo }}
                </div>

            </v-col>

        </v-row>


        <v-form style="" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-list class="container-domande">


                        <v-list-tile v-for="el in domande_risposte" :key="el.id_domanda">

                            <div class="clDomanda">{{ el.domanda }}</div>


                            <v-list-tile v-for="el2 in el.risposte" :key="el2.id_risposta">

                                <div class="clRowRisposta">


                                    <div>

                                        <v-radio-group v-model="valueSelected[el.posizione_domanda]">

                                            <v-radio :label="el2.risposta" :value="el2.id_risposta" color="#028bff"
                                                class="clRisposta"></v-radio>

                                        </v-radio-group>


                                    </div>



                                </div>


                            </v-list-tile>

                            <div v-if="!el.ultima_domanda" class="clSpazio"></div>
                            <div v-if="!el.ultima_domanda" class="clRiga"></div>


                        </v-list-tile>



                        <div style="height:80px">&nbsp;</div>





                    </v-list>





                </v-col>


            </v-row>





        </v-form>




    </v-container>
</template>

<script>

import Vue from "vue";
import VModal from 'vue-js-modal';

import apicorsi from "../utils/corsi/apicorsi";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        this.verifyStep();


        this.setupButtons();



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {


                case "btn_back":

                    history.back();

                    break;


                case "btn_next_domande":

                    this.validate();

                    break;


                default:
                    break;
            }
        });


        setTimeout(() => {

            this.getDomandeRisposte();


        }, 100);


    },


    data: () => ({

        showMsgNextStep: false,

        answers: [],

        valueSelected: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9], [10], [11], [12], [13], [14], [15], [16], [17], [18], [19], [20], [21], [22], [23], [24], [25], [26], [27], [28], [29], [30], [31], [32], [33], [34], [35], [36], [37], [38], [39], [40]],


        titolo: "Presentazione Domande",

        domande_risposte: [],


    }),


    methods: {


        validate() {


            console.log("RISPOSTE: ", this.valueSelected);

            var errore = "";


            var numRiposte = 0;

            for (var x = 0; x < this.valueSelected.length; x++) {

                if (Number.isInteger(this.valueSelected[x])) {
                    numRiposte = numRiposte + 1;
                }
            }

            if (parseInt(numRiposte) < parseInt(this.domande_risposte.length)) {
                errore = "Rispondere a tutte le domande";
            }


            if (errore == "") {

                console.log("ok");

                this.validateDomandeRisposte();


            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });



            }





        },


        sendMail: async function (v_res_corso, v_num_domande, v_num_risp_ok) {

            var that = this;

            that.$swal
                .fire({
                    title: "Conferma",
                    html: "Sei sicuro di aver risposto correttamente a tutte le domande ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel_smartform.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apicorsi.sendMail(
                            window.tokenuser,
                            v_res_corso,
                            v_num_domande,
                            v_num_risp_ok
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from sendMail", res);

                            that.$swal({
                                icon: "success",
                                text: "Corso completato con successo, una mail le è stata inviata con il risultato ottenuto",
                                showConfirmButton: false,
                                timer: 3000
                            });


                            setTimeout(() => {

                                that.goNextStep();


                            }, 3000);


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },






        validateDomandeRisposte: async function () {


            var strRisposte = "";

            for (var x = 0; x < this.valueSelected.length; x++) {
                if (Number.isInteger(this.valueSelected[x])) {


                    if (strRisposte != "") {
                        strRisposte = strRisposte + ",";
                    }
                    strRisposte = strRisposte + this.valueSelected[x].toString();

                }
            }

            console.log("RISPOSTE SEL: ", strRisposte);


            var that = this;

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            var response = await apicorsi.validateDomandeRisposte(
                window.tokenuser,
                strRisposte
            ).then((res) => {

                // that.$root.$children[0].showProgress = false;
                console.log("res from validateDomandeRisposte", res);

                var n_domande = res.data.NumDomande;
                var n_risp_ok = res.data.NumRispOk;

                var res_corso = "";

                if (res.data.Result == "OK") {

                    res_corso = "PASSED";

                }
                else {


                    res_corso = "NOT_PASSED";

                }

                that.sendMail(res_corso, n_domande, n_risp_ok);

                // setTimeout(() => {

                //     that.goNextStep();


                // }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },







        getDomandeRisposte: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.getDomandeRisposte(
                window.tokenuser
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDomandeRisposte", res);

                that.domande_risposte = res.data.Result;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },







        verifyStep: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.verifyStep(
                window.tokenuser
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from verifyStep", res);

                if (res.data.Result == "ERROR") {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });


                    setTimeout(() => {


                        if (res.data.PageNextStep == "vediDomande") {

                            var token_step = window.$cookies.get("username");
                            token_step = token_step + "|" + res.data.IdCorso.toString();
                            token_step = token_step + "|" + res.data.IdNextStep.toString();
                            token_step = btoa(token_step);


                            router.push({
                                path: "/vediDomande/" + token_step
                            });


                        }

                        if (res.data.PageNextStep == "vediPdf") {

                            var token_step2 = window.$cookies.get("username");
                            token_step2 = token_step2 + "|" + res.data.IdCorso.toString();
                            token_step2 = token_step2 + "|" + res.data.IdNextStep.toString();
                            token_step2 = btoa(token_step2);


                            router.push({
                                path: "/vediPdf/" + token_step2
                            });


                        }

                        if (res.data.PageNextStep == "corsiOnLine") {

                            router.push({
                                path: "/corsiOnLine"
                            });

                        }

                    }, 10);




                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        goNextStep: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicorsi.goNextStep(
                window.tokenuser
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from goNextStep", res);

                if (res.data.Result == "OK") {

                    var token_step = window.$cookies.get("username");
                    token_step = token_step + "|" + res.data.IdCorso.toString();
                    token_step = token_step + "|" + res.data.IdNextStep.toString();
                    token_step = btoa(token_step);


                    if (res.data.PageNextStep == "vediDomande") {


                        setTimeout(() => {

                            router.push({
                                path: "/vediDomande/" + token_step
                            });

                        }, 100);

                    }

                    if (res.data.PageNextStep == "vediPdf") {

                        setTimeout(() => {

                            router.push({
                                path: "/vediPdf/" + token_step
                            });

                        }, 100);

                    }

                }
                else {

                    if (that.showMsgNextStep) {


                        that.$swal({
                            icon: "warning",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 3000
                        });


                        setTimeout(() => {

                            router.push({
                                path: "/" + res.data.PageNextStep
                            });

                        }, 2500);

                    }
                    else {

                        setTimeout(() => {

                            router.push({
                                path: "/" + res.data.PageNextStep
                            });

                        }, 200);

                    }



                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        setupButtons: async function () {

            setTimeout(() => {



                var pulsantis = [];

                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_next_domande",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/btn_confirm_smartform.png",
                    title: "Conferma e vai allo step successivo",
                    width: 30
                });

                pulsantis.push({
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back_smartform.png",
                    title: "Indietro",
                    width: 30

                });


                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },




    }

})
</script>

<style>
.clRowRisposta {
    display: flex !important;
    height: 60px;
}

.clSpazio {
    height: 10px;
}

.clRiga {
    width: 98%;
    border: 1px solid gray;
    margin-top: 5px;
    margin-bottom: 5px;
}

.clDomanda {
    font-size: 16px;
    padding: 5px 5px 5px 5px;
    font-weight: bold;
}

.clRisposta label {
    font-size: 16px !important;
}

.clRisposta i {
    font-size: 15px !important;
}

.container-domande {
    overflow-y: auto;
    max-height: 80vh;
    min-height: 80vh;
}

.style-iframe-contenitor {
    display: flex;
    justify-content: center;
}

.style-iframe {
    width: 94vw;
    height: 73vh;
    min-width: 94vw;
    max-width: 94vw;
    min-height: 73vh;
    max-height: 73vh;
}

.w-container-domande {
    width: 1000px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-domande {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
